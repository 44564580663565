<template>
  <v-row justify="center">
    <v-dialog ref="dialog" :value="value" @input="updateModel()" width="500" persistent>
      <v-card class="pa-4">
        <v-card-title>
          <div>
            <v-icon color="default" @click="updateModel(false)">close</v-icon><br>
            <h2 class="mt-2 font-weight-bold headline">{{title}}</h2>
          </div>
        </v-card-title>
        <v-card-text class="ma-0 pa-0">
          <component v-if="title === '리뷰 평가하기'" :is="contents" @star="(val) => stars = val"></component>
          <div v-else class="review-direction" v-html="contents"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="title === '리뷰 평가하기'">
            <v-btn color="rouge" outlined flat @click.native="updateModel(false)">취소</v-btn>
            <v-btn class="rouge white-two--text" flat @click="regist">저장</v-btn>
          </div>
          <div v-else>
            <v-btn class="rouge white-two--text" flat @click.native="updateModel(false)">확인</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import Star from "./Star";

  export default {
    name: "RateDialog",
    props: ['value', 'contract'],
    components: {
      Star // Star: () => import('./Star')
    },
    data() {
      return {
        title: '리뷰 평가하기',
        contents: Star,
        stars: 0
      }
    },
    watch: {
      // value (val) {
      //   this.showDialog = val;
      // }
      // showDialog (value) {
      //   this.$emit('input', value);
      // }:
    },
    methods: {
      updateModel(val) {
        const showDialog = (val === undefined) ? this.$refs.dialog.value : val;
        if(!showDialog) {
          this.title = '리뷰 평가하기';
          this.contents = Star;
        }
        this.$emit('input', showDialog);
      },
      regist () {
        if (this.stars > 0) {
          this.axios.put('/contract?id=' + (this.contract.id || this.contract.rKey), {
            mark: this.stars,
            currentState: 2,
            date2: new Date()
          }).then(() => {
            // return this.axios.get('/contract?company=' + this.company.id + '&orderBy=date0&direction=desc')
            return null;
          }).then(() => {
            this.title = '평가 완료';
            this.contents = '평가가 완료되었습니다.</br> 감사합니다!';
            this.$emit('complete');
          }).catch(() => {
            this.title = '오류 알림';
            this.contents = {
              template: '<div class="review-direction">평가 저장 중 오류가 발생했습니다.<br/>잠시 후 다시 확인해주십시오</div>'
            }
          })
        } else {
          //
        }
      }
    }
  }
</script>

<style scoped>

</style>