<template>
  <div>
    <div class="review-direction">고객님의 리뷰가 만족스러우셨나요?<br/>만족도를 5점 만점으로 평가해주세요.</div>
    <div class="review-stars">
      <i v-for="(val, idx) in isActive" @mouseover="mouseOver(idx)" :class="val ? 'fas fa-star active' : 'far fa-star'" :key="idx"></i>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'star',
    data () {
      return {
        isActive: [false, false, false, false, false]
      }
    },
    methods: {
      mouseOver (idx) {
        this.isActive.forEach((value, n) => {
          if (n > idx) {
            this.$set(this.isActive, n, false)
          } else {
            this.$set(this.isActive, n, true)
          }
        })
        this.$emit('star', idx + 1)
      }
    }
  }
</script>

<style scoped>
  .review-stars {
    padding:40px 0 40px;
    text-align:center;
  }
  .review-stars i {
    cursor:pointer;
    color:#494648;
    font-size:40px;
  }
  .review-stars i:hover,
  .review-stars i.active {
    color:#f4cb00;
  }

  .review-direction {
    font-size:14px;
  }

  @media(max-width:768px) {
    .review-direction {
      font-size:14px;
    }
    .review-stars {
      padding: 10px 0;
    }
  }
</style>
